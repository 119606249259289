import React from "react";
import Container from "../../components/ui/Container";
import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
import Salah from "../../assets/Updated-imagery/salah.webp";

const CtaTeachers = () => {
    const token = auth.getToken();

    return (
        <section className="h-full w-full my-20">
            <Container className="h-full p-28 flex  justify-center items-center relative z-10  pt-32 md:pt-0 pb-20 md:pb-0 space-y-4">
                <div className="relative max-w-5xl px-4 lg:px-[60px] py-10  lg:py-[30px] rounded-[48px] bg-primSky-900 m-auto flex flex-col md:flex-row justify-between items-center Cta-Bg bg-no-repeat gap-10 ">
                    <div className="flex flex-col items-center lg:items-start text-white ">
                        <h1 className="vipFont text-center md:text-right text-2xl md:text-4xl">
                            عايز تنضم لينا كـ معلِّم ؟
                        </h1>
                        <p className="text-lg md:text-xl text-center md:text-right mt-6 w-full md:w-[70%]">
                            يمكنك الآن الانضمام لفريق المعلمين على المنصة، والمشاركة في تدريس
                            المناهج التعليمية للصفوف الثانوية
                        </p>
                        <div className="mt-16">
                            <Button
                                color="NewYello"
                                className="text-xl font-bold inline-block scale smooth shadow-md hover:shadow-lg  border-none  md:text-3xl px-[25px] md:px-[50px] py-[15px] md:py-[25px] rounded-xl sm:rounded-3xl "
                                element="Link"
                                to={`https://www.facebook.com/bassthalkedu`}
                            >
                                {!token && <span className="text-black"> ابدأ رحلتك الأن </span>}
                            </Button>
                        </div>
                    </div>
                    <div className="flex justify-center items-end w-full lg:w-[60%]">
                        <img src={Salah} alt="M/Salah" />
                    </div>
                    <div></div>
                </div>
            </Container>
        </section>
    );
};

export default CtaTeachers;
