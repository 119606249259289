import React, { useContext, useEffect, useState } from "react";

import ScrollingContext from "../../context/ScrollingContext";

import { getScrollingPercentage, windowHeight } from "../../services/scrollingServices";

import Waves from "./Waves";

import "./sectionHead.css";

const SectionHead = ({ className = "bg-sky-500 text-slate-100", title = "الكورسات" }) => {
    const [percentage, setPercentage] = useState(0);
    const { scrolling } = useContext(ScrollingContext);

    useEffect(() => {
        const yearsHeader = document.querySelector(".home-years__head");

        let { percentage: percent } = getScrollingPercentage(
            scrolling,
            yearsHeader.getBoundingClientRect().top + scrolling,
            (windowHeight + yearsHeader.style.height) / 2
        );
        setPercentage(percent);
    }, [scrolling]);

    return (
        <>
            <div className={`home-years__head ${className}`}>
                {/* <Waves className="fill-sky-500 bg-primary-container transform translate-y-1/4 scale-y-50 -mt-24 smooth " /> */}
                <svg width="100%" height="auto" xmlns="http://www.w3.org/2000/svg">
                    <path className="fill-primary-container smooth" d="M0 0h3000v100H0z" />
                    <path
                        d="m0 29 16.2-3.3c16.1-3.4 48.5-10 80.8-1s64.7 33.6 96.8 45.6c32.2 12 64.2 11.4 96.4 13.5 32.1 2.2 64.5 7.2 96.8-2 32.3-9.1 64.7-32.5 97-43s64.7-8.1 96.8-7.5c32.2.7 64.2-.3 96.4 8.4 32.1 8.6 64.5 27 96.8 33.3 32.3 6.3 64.7.7 97-13.5s64.7-36.8 97-33.5c32.3 3.3 64.7 32.7 96.8 42.3 32.2 9.7 64.2-.3 96.4-6.6 32.1-6.4 64.5-9 96.8-18.5s64.7-25.9 97-32.2c32.3-6.3 64.7-2.7 96.8 10.7C1484 35 1516 58 1548.2 57.8c32.1-.1 64.5-23.5 96.8-21.6 32.3 1.8 64.7 28.8 97 39.1 32.3 10.4 64.7 4 96.8 4 32.2 0 64.2 6.4 96.4-4.6 32.1-11 64.5-39.4 96.8-39 32.3.3 64.7 29.3 97 33.1 32.3 3.9 64.7-17.5 97-23.5s64.7 3.4 96.8 7.4c32.2 4 64.2 2.6 96.4 5.3 32.1 2.7 64.5 9.3 96.8 13.2 32.3 3.8 64.7 4.8 97 2.6 32.3-2.1 64.7-7.5 96.8-8.1 32.2-.7 64.2 3.3 96.4-3.7 32.1-7 64.5-25 96.8-36.5s64.7-16.5 80.8-19L3000 4v97H0Z"
                        className="fill-sky-500"
                    />
                </svg>
                <div className="relative px-10 overflow-hidden">
                    <div className="home-years__title font-big font-w-bold relative">
                        <div
                            className="home-years-title-bg__container"
                            style={{ transform: `translateX(${-100 * (1 - percentage)}%)` }}
                        >
                            <div className="home-years-title__bg font-bigmax text-slate-50">
                                {title}
                            </div>
                        </div>

                        <div className="home-years-title__content relative z-10 text-slate-50">
                            {title}
                        </div>
                    </div>
                </div>

                {/* <Waves className="fill-primary-container transform translate-y-1/2 scale-y-50 -mt-10" /> */}
                {/* <Waves className="fill-primary-container" /> */}
            </div>
            <svg width="100%" height="auto" xmlns="http://www.w3.org/2000/svg">
                <path className="fill-sky-500" d="M0 0h3000v100H0z" />
                <path
                    d="m0 56 38.5-4.5C77 47 154 38 231 44s154 27 230.8 26c76.9-1 153.5-24 230.4-32.2C769 29.7 846 36.3 923 47.7c77 11.3 154 27.3 231 33.6 77 6.4 154 3 230.8-6.8 76.9-9.8 153.5-26.2 230.4-32.5 76.8-6.3 153.8-2.7 230.8-1.7 77 1 154-.6 231 2.4s154 10.6 230.8 18c76.9 7.3 153.5 14.3 230.4 4.6 76.8-9.6 153.8-36 230.8-40.8 77-4.8 154 11.8 192.5 20.2L3000 53v48H0Z"
                    className="fill-primary-container smooth"
                />
            </svg>
        </>
    );
};

export default SectionHead;
