import "./scroll.css";
import Content from "./Content";
import ParallaxImage from "./Parallax";


const Teachers = () => {

  return (
    <div className="TeacherScroll">
      <div className="firstLine">
        <ParallaxImage baseVelocity={-5}>
          <Content/>
        </ParallaxImage>
      </div>
    </div>
  )
}

export default Teachers