import Container from "../../components/ui/Container";
import React from "react";
import Heading from "../../components/NewUi/heading/Heading";
import Card from "../../components/NewUi/featureCard/Card";
// import auth from '../../services/authServices'
const FeatureSection = () => {
    // const token = auth.getToken();

    return (
        <section className=" min-h-screen md:h-fit  relative overflow-hidden flex justify-center items-start md:items-start xl:items-start my-10">
            <Container className="h-fit relative z-10  pb-20 md:pb-0 space-y-4 flex flex-col items-center justify-center gap-[104px] md:gap-[120px]">
                <div className="flex flex-col items-center justify-center h-fit">
                    <Heading title="ازاي" subtitle="بسطتنهالك" />
                </div>
                <div className=" w-full h-full flex justify-center items-center">
                    <Card />
                </div>
            </Container>
        </section>
    );
};

export default FeatureSection;
