// import React from 'react'
import "./card.css";

const Card = () => {
    return (
        <div className="w-full h-full flex flex-wrap justify-center gap-3">
            <div className="flex justify-center flex-col items-center">
                <div
                    className="min-h-[345px] bg-primSky-800 dark:bg-primSky-300 flex justify-center flex-col items-center px-2 py-5"
                    style={{ borderRadius: "24px" }}
                >
                    <div className="svg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="120"
                            height="104"
                            viewBox="0 0 120 104"
                            fill="none"
                        >
                            <g clipPath="url(#clip0_149_1708)">
                                <path
                                    d="M119.288 68.6689C114.701 98.8823 80.5838 109.213 51.6767 100.567C22.5765 91.8689 15.86 66.7393 28.5732 40.8527C41.5313 15.456 62.7052 -3.21677 83.6564 0.872537C104.37 4.64271 124.045 38.1439 119.28 68.6689H119.288Z"
                                    fill="#CEEBFF"
                                />
                                <path
                                    d="M28.6539 25.95C33.51 15.8779 31.637 4.91183 24.4706 1.4567C17.3042 -1.99843 7.55805 3.36571 2.70199 13.4378C-2.15406 23.51 -0.281132 34.476 6.88529 37.9311C14.0517 41.3862 23.7979 36.0221 28.6539 25.95Z"
                                    fill="#CEEBFF"
                                />
                                <path
                                    d="M16.231 56.7943C12.5351 53.3284 10.2938 48.7938 10.2938 43.8288C10.2938 32.9339 21.0848 24.0947 34.3991 24.0947C47.7135 24.0947 58.5045 32.9264 58.5045 43.8288C58.5045 54.7311 47.7135 63.5629 34.3991 63.5629C30.0427 63.5629 25.9608 62.6129 22.4355 60.9579C18.6876 63.5703 13.7151 67.0287 13.7151 67.0287L16.231 56.7943Z"
                                    fill="white"
                                />
                                <path
                                    d="M13.7151 67.6819C13.5815 67.6819 13.4554 67.6448 13.344 67.5631C13.1214 67.4073 13.0101 67.1327 13.0769 66.8655L15.5037 57.0022C11.7187 53.3211 9.63324 48.6603 9.63324 43.8288C9.63324 32.5851 20.7434 23.4417 34.3917 23.4417C48.0401 23.4417 59.1502 32.5851 59.1502 43.8288C59.1502 55.0725 48.0401 64.216 34.3917 64.216C30.2356 64.216 26.1315 63.3476 22.4949 61.7075C18.7915 64.2902 14.0862 67.5631 14.0862 67.5631C13.9749 67.6373 13.8413 67.6819 13.7151 67.6819ZM34.3991 24.7553C21.4707 24.7553 10.9543 33.3124 10.9543 43.8288C10.9543 48.4079 12.9878 52.8461 16.6838 56.312C16.8545 56.4752 16.9287 56.7201 16.8693 56.9502L14.769 65.4925C16.5205 64.2753 19.5634 62.1602 22.057 60.4161C22.25 60.2825 22.4949 60.2603 22.7101 60.3642C26.2576 62.0266 30.295 62.9098 34.3917 62.9098C47.3202 62.9098 57.8366 54.3527 57.8366 43.8362C57.8366 33.3198 47.3202 24.7627 34.3917 24.7627L34.3991 24.7553Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M99.0043 66.8878C103.48 62.6871 106.203 57.1951 106.203 51.1762C106.203 37.9732 93.1264 27.2638 76.9918 27.2638C60.8572 27.2638 47.7803 37.9658 47.7803 51.1762C47.7803 64.3867 60.8572 75.0886 76.9918 75.0886C82.2686 75.0886 87.2188 73.9383 91.4862 71.9345C96.0282 75.1035 102.055 79.2893 102.055 79.2893L99.0043 66.8878Z"
                                    fill="white"
                                />
                                <path
                                    d="M102.055 79.9424C101.921 79.9424 101.795 79.9053 101.683 79.8237C101.683 79.8237 95.9243 75.8234 91.4268 72.6841C87.0258 74.6879 82.0385 75.7418 76.9992 75.7418C60.5307 75.7418 47.1346 64.7207 47.1346 51.1762C47.1346 37.6318 60.5307 26.6107 76.9992 26.6107C93.4678 26.6107 106.864 37.6318 106.864 51.1762C106.864 57.0171 104.333 62.6575 99.739 67.0956L102.7 79.126C102.767 79.3932 102.656 79.6678 102.433 79.8237C102.322 79.8979 102.188 79.9424 102.062 79.9424H102.055ZM91.4862 71.274C91.6198 71.274 91.7459 71.3111 91.8647 71.3927C95.0411 73.6118 98.9449 76.3207 101.001 77.753L98.366 67.0436C98.3066 66.8136 98.3809 66.5687 98.5516 66.4054C103.056 62.1751 105.543 56.7647 105.543 51.1762C105.543 38.3517 92.733 27.9243 76.9918 27.9243C61.2505 27.9243 48.4408 38.3591 48.4408 51.1762C48.4408 63.9934 61.2505 74.4282 76.9918 74.4282C81.9791 74.4282 86.8922 73.3594 91.2116 71.3333C91.3006 71.2888 91.3971 71.274 91.4936 71.274H91.4862Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M89.5714 52.2523C89.5714 53.7737 88.3394 55.0057 86.818 55.0057C85.2965 55.0057 84.0645 53.7737 84.0645 52.2523C84.0645 50.7309 85.2965 49.4989 86.818 49.4989C88.3394 49.4989 89.5714 50.7309 89.5714 52.2523Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M76.9918 55.0057C78.5124 55.0057 79.7452 53.773 79.7452 52.2523C79.7452 50.7316 78.5124 49.4989 76.9918 49.4989C75.4711 49.4989 74.2383 50.7316 74.2383 52.2523C74.2383 53.773 75.4711 55.0057 76.9918 55.0057Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M67.1656 55.0057C68.6862 55.0057 69.919 53.773 69.919 52.2523C69.919 50.7316 68.6862 49.4989 67.1656 49.4989C65.6449 49.4989 64.4121 50.7316 64.4121 52.2523C64.4121 53.773 65.6449 55.0057 67.1656 55.0057Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M44.2254 44.4522C44.2254 45.6396 43.2606 46.6045 42.0731 46.6045C40.8856 46.6045 39.9208 45.6396 39.9208 44.4522C39.9208 43.2647 40.8856 42.2999 42.0731 42.2999C43.2606 42.2999 44.2254 43.2647 44.2254 44.4522Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M34.3917 46.6045C35.5804 46.6045 36.544 45.6409 36.544 44.4522C36.544 43.2635 35.5804 42.2999 34.3917 42.2999C33.2031 42.2999 32.2395 43.2635 32.2395 44.4522C32.2395 45.6409 33.2031 46.6045 34.3917 46.6045Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M28.8626 44.4522C28.8626 45.6396 27.8978 46.6045 26.7104 46.6045C25.5229 46.6045 24.5581 45.6396 24.5581 44.4522C24.5581 43.2647 25.5229 42.2999 26.7104 42.2999C27.8978 42.2999 28.8626 43.2647 28.8626 44.4522Z"
                                    fill="#3A2382"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_149_1708">
                                    <rect
                                        width="120"
                                        height="103.197"
                                        fill="white"
                                        transform="translate(0 0.308472)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="heading relative mt-6">
                        <h1 className="header-card dark:text-primSky-950 relative z-10">هـتشارك</h1>
                        <div className="absolute inset-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="95"
                                height="44"
                                viewBox="0 0 95 44"
                                fill="none"
                            >
                                <ellipse
                                    cx="47.5"
                                    cy="21.8085"
                                    rx="47.5"
                                    ry="21.5"
                                    className="fill-secYellow-800 dark:fill-secYellow-400"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="desc mt-10">
                        <p className="dark:text-primSky-950">
                            مجموعات للمناقشة عشان تسأل وتشارك أفكارك مع زمايلك
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex justify-center flex-col items-center">
                <div
                    className=" min-h-[345px] bg-primSky-800 dark:bg-primSky-300 flex justify-center flex-col items-center px-2 py-5"
                    style={{ borderRadius: "24px" }}
                >
                    <div className="svg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="95"
                            height="99"
                            viewBox="0 0 95 99"
                            fill="none"
                        >
                            <g clipPath="url(#clip0_149_1731)">
                                <path
                                    d="M91.1701 32.4151C97.0817 27.1425 96.0307 16.3169 88.8227 8.23529C81.6146 0.153708 70.9791 -2.12345 65.0676 3.14911C59.156 8.42167 60.207 19.2473 67.415 27.3289C74.623 35.4105 85.2586 37.6877 91.1701 32.4151Z"
                                    fill="#CEEBFF"
                                />
                                <path
                                    d="M58.0336 32.9185C79.1754 47.7229 98.4256 65.1858 83.986 80.9584C69.9338 96.1724 30.1599 105.801 11.7735 90.118C-7.08199 74.4796 -0.238298 37.4834 11.0959 23.3418C22.9066 9.11819 36.5047 18.7098 58.0336 32.9185Z"
                                    fill="#CEEBFF"
                                />
                                <path
                                    d="M44.4579 70.7114C62.1636 70.7114 76.5168 66.7705 76.5168 61.9092C76.5168 57.0478 62.1636 53.1069 44.4579 53.1069C26.7523 53.1069 12.3991 57.0478 12.3991 61.9092C12.3991 66.7705 26.7523 70.7114 44.4579 70.7114Z"
                                    fill="white"
                                />
                                <path
                                    d="M44.4579 71.3742C28.3727 71.3742 11.7363 67.8369 11.7363 61.9092C11.7363 55.9815 28.3727 52.4442 44.4579 52.4442C60.5432 52.4442 77.1796 55.9815 77.1796 61.9092C77.1796 67.8369 60.5432 71.3742 44.4579 71.3742ZM44.4579 53.7623C25.6769 53.7623 13.0544 57.9773 13.0544 61.9092C13.0544 65.8412 25.6694 70.0561 44.4579 70.0561C63.2464 70.0561 75.8615 65.8412 75.8615 61.9092C75.8615 57.9773 63.2464 53.7623 44.4579 53.7623Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M44.4579 67.4571C33.3025 67.4571 22.013 65.5507 22.013 61.9166C22.013 58.2825 33.3025 56.3687 44.4579 56.3687C55.6134 56.3687 66.9029 58.2751 66.9029 61.9166C66.9029 65.5581 55.6134 67.4571 44.4579 67.4571ZM44.4579 57.6868C31.3663 57.6868 23.3311 60.1517 23.3311 61.9166C23.3311 63.6815 31.3663 66.139 44.4579 66.139C57.5496 66.139 65.5848 63.6741 65.5848 61.9166C65.5848 60.1591 57.5496 57.6868 44.4579 57.6868Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M44.4579 62.5719C44.093 62.5719 43.8026 62.2741 43.8026 61.9166V13.1469C43.8026 12.782 44.1005 12.4916 44.4579 12.4916C44.8154 12.4916 45.1133 12.7895 45.1133 13.1469V61.9092C45.1133 62.2741 44.8154 62.5645 44.4579 62.5645V62.5719Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M84.1573 40.5886C82.2211 41.4525 78.7285 43.0685 74.6551 42.1748C64.9518 40.0376 54.0719 37.4237 44.5696 40.5886V16.8331C57.2368 13.6681 71.4902 18.4192 84.1573 16.8331V40.5886Z"
                                    fill="white"
                                />
                                <path
                                    d="M77.4253 42.8525C75.9434 42.8525 74.387 42.6813 72.7785 42.3313L71.9965 42.16C63.0752 40.2163 53.8485 38.1982 44.7782 41.2217C44.5845 41.2887 44.3611 41.2515 44.1824 41.1323C44.0111 41.0057 43.9069 40.8121 43.9069 40.5961V16.8405C43.9069 16.5352 44.1154 16.2746 44.4058 16.2001C51.5623 14.4129 59.2698 15.1129 66.7093 15.798C72.5178 16.3342 78.52 16.8778 84.068 16.1852C84.2616 16.1629 84.4477 16.2224 84.5892 16.3416C84.7307 16.4682 84.8126 16.6469 84.8126 16.8331V40.5887C84.8126 40.827 84.686 41.0429 84.485 41.1621C82.5339 42.2791 80.1137 42.8525 77.4253 42.8525ZM54.8538 38.3472C60.7294 38.3472 66.5603 39.6206 72.2721 40.8717L73.054 41.0429C77.0902 41.9217 80.769 41.6238 83.4945 40.2014V17.5778C78.0211 18.1512 72.2125 17.6225 66.5901 17.1086C59.4336 16.4533 52.0314 15.7756 45.225 17.3618V39.6951C48.4346 38.7344 51.6516 38.3546 54.8538 38.3546V38.3472Z"
                                    fill="#3A2382"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_149_1731">
                                    <rect
                                        width="95"
                                        height="97.8298"
                                        fill="white"
                                        transform="translate(0 0.308472)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="heading relative mt-6">
                        <h1 className="header-card relative dark:text-primSky-950 z-10">هـتنافس</h1>
                        <div className="absolute inset-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="95"
                                height="44"
                                viewBox="0 0 95 44"
                                fill="none"
                            >
                                <ellipse
                                    cx="47.5"
                                    cy="21.8085"
                                    rx="47.5"
                                    ry="21.5"
                                    className="fill-secYellow-800 dark:fill-secYellow-400"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="desc mt-10">
                        <p className="dark:text-primSky-950">
                            نظام نقط على كل حاجة بتذاكرها وهدايا للطلبة النشيطة
                        </p>
                    </div>
                </div>
            </div>
            <div className=" flex justify-center flex-col items-center">
                <div
                    className="min-h-[345px] bg-primSky-800 dark:bg-primSky-300 flex justify-center flex-col items-center px-2 py-5"
                    style={{ borderRadius: "24px" }}
                >
                    <div className="svg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100"
                            height="104"
                            viewBox="0 0 100 104"
                            fill="none"
                        >
                            <g clipPath="url(#clip0_149_1748)">
                                <path
                                    d="M23.3794 22.0002C29.3029 15.4055 30.2636 6.67404 25.5251 2.49804C20.7867 -1.67796 12.1435 0.282836 6.22 6.8776C0.296511 13.4724 -0.664156 22.2038 4.07429 26.3798C8.81273 30.5558 17.4559 28.595 23.3794 22.0002Z"
                                    fill="#CEEBFF"
                                />
                                <path
                                    d="M18.0831 33.9005C35.2809 11.6758 51.4325 -1.80404 72.1713 11.4446C92.5076 24.8448 110.293 62.8052 93.0227 84.6074C76.1629 106.346 31.5468 108.283 11.3874 95.7277C-9.30307 82.6544 1.40834 56.6034 18.0831 33.9005Z"
                                    fill="#CEEBFF"
                                />
                                <path
                                    d="M21.286 18.4517C15.1778 18.4517 10.2446 23.3542 10.2446 29.3886C10.2446 30.8394 10.5344 32.2026 11.0333 33.47L11.0494 33.486C11.5564 34.7216 21.278 49.3732 21.278 49.3732C21.278 49.3732 31.0076 34.7375 31.5065 33.486V33.47C32.0296 32.2026 32.3113 30.8394 32.3113 29.3886C32.3113 23.3542 27.362 18.4517 21.2699 18.4517H21.286ZM21.286 33.6374C18.6947 33.6374 16.6103 31.5728 16.6103 29.006C16.6103 26.4391 18.6947 24.3905 21.286 24.3905C23.8773 24.3905 25.9456 26.4551 25.9456 29.006C25.9456 31.5569 23.8613 33.6374 21.286 33.6374Z"
                                    fill="white"
                                />
                                <path
                                    d="M21.286 50.0827C21.0446 50.0827 20.8192 49.9631 20.6905 49.7638C19.7087 48.2891 11.2667 35.5586 10.4378 33.8447C10.4136 33.8048 10.3895 33.765 10.3734 33.7251C9.81812 32.3221 9.53645 30.8633 9.53645 29.3806C9.53645 22.9635 14.8077 17.7422 21.286 17.7422C27.7644 17.7422 33.0356 22.9635 33.0356 29.3806C33.0356 30.8554 32.7539 32.2982 32.2067 33.6613C32.2067 33.6853 32.1906 33.7171 32.1825 33.7411C31.6675 35.0245 22.8794 48.2492 21.8815 49.7559C21.7528 49.9552 21.5274 50.0747 21.286 50.0747V50.0827ZM11.6691 33.1432C11.6691 33.1432 11.7013 33.199 11.7174 33.2309C12.0473 34.044 17.4151 42.2467 21.286 48.0978C24.9799 42.5257 30.3316 34.3469 30.8386 33.2867C30.8386 33.2628 30.8547 33.2388 30.8627 33.2149C31.3617 31.9953 31.6192 30.7119 31.6192 29.3966C31.6192 23.7527 26.9837 19.1691 21.2941 19.1691C15.6044 19.1691 10.9689 23.7607 10.9689 29.3966C10.9689 30.68 11.2104 31.9395 11.6771 33.1512L11.6691 33.1432ZM21.286 34.3469C18.3164 34.3469 15.8941 31.9554 15.8941 29.006C15.8941 26.0565 18.3164 23.681 21.286 23.681C24.2556 23.681 26.6618 26.0645 26.6618 29.006C26.6618 31.9475 24.2475 34.3469 21.286 34.3469ZM21.286 25.0919C19.0971 25.0919 17.3185 26.8457 17.3185 29.006C17.3185 31.1662 19.0971 32.9359 21.286 32.9359C23.475 32.9359 25.2374 31.1742 25.2374 29.006C25.2374 26.8377 23.4669 25.0919 21.286 25.0919Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M74.5695 48.7115C68.4613 48.7115 63.5281 53.614 63.5281 59.6485C63.5281 61.0993 63.8178 62.4624 64.3168 63.7299L64.3329 63.7459C64.8399 64.9814 74.5614 79.6331 74.5614 79.6331C74.5614 79.6331 84.291 64.9974 84.79 63.7459V63.7299C85.3131 62.4624 85.5947 61.0993 85.5947 59.6485C85.5947 53.614 80.6454 48.7115 74.5534 48.7115H74.5695ZM74.5695 63.8973C71.9781 63.8973 69.8938 61.8327 69.8938 59.2659C69.8938 56.699 71.9781 54.6503 74.5695 54.6503C77.1608 54.6503 79.229 56.715 79.229 59.2659C79.229 61.8167 77.1447 63.8973 74.5695 63.8973Z"
                                    fill="white"
                                />
                                <path
                                    d="M74.5694 80.3426C74.328 80.3426 74.1027 80.223 73.9739 80.0237C72.9921 78.549 64.5501 65.8185 63.7212 64.1046C63.6971 64.0647 63.6729 64.0249 63.6568 63.985C63.1016 62.59 62.8118 61.1232 62.8118 59.6405C62.8118 53.2234 68.083 48.0021 74.5614 48.0021C81.0398 48.0021 86.311 53.2234 86.311 59.6405C86.311 61.1152 86.0293 62.5501 85.474 63.9212C85.474 63.9451 85.4579 63.977 85.4499 64.0009C84.9348 65.2844 76.1468 78.5091 75.1489 80.0158C75.0201 80.215 74.7948 80.3346 74.5533 80.3346L74.5694 80.3426ZM64.9525 63.3951C64.9525 63.3951 64.9847 63.4509 65.0008 63.4828C65.3308 64.2959 70.6985 72.5066 74.5694 78.3497C78.2633 72.7776 83.615 64.5988 84.122 63.5306C84.122 63.5067 84.1381 63.4828 84.1461 63.4589C84.6451 62.2392 84.9026 60.9558 84.9026 59.6405C84.9026 53.9967 80.2672 49.413 74.5694 49.413C68.8717 49.413 64.2443 54.0046 64.2443 59.6405C64.2443 60.9239 64.4858 62.1834 64.9525 63.3871V63.3951ZM74.5614 64.5988C71.5918 64.5988 69.1695 62.1994 69.1695 59.2579C69.1695 56.3164 71.5838 53.9329 74.5614 53.9329C77.539 53.9329 79.9372 56.3164 79.9372 59.2579C79.9372 62.1994 77.531 64.5988 74.5614 64.5988ZM74.5614 55.3439C72.3724 55.3439 70.5939 57.0976 70.5939 59.2579C70.5939 61.4182 72.3724 63.1878 74.5614 63.1878C76.7504 63.1878 78.5128 61.4261 78.5128 59.2579C78.5128 57.0896 76.7423 55.3439 74.5614 55.3439Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M74.5695 80.3426C74.5212 80.3426 74.4729 80.3426 74.4326 80.3267L25.8168 70.6652C25.4306 70.5855 25.1811 70.2188 25.2616 69.8361C25.342 69.4535 25.7122 69.1904 26.0985 69.2861L74.7224 78.9476C75.1086 79.0273 75.3581 79.394 75.2776 79.7766C75.2052 80.1114 74.9155 80.3426 74.5775 80.3426H74.5695Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M25.9456 66.9504C25.7042 66.9504 25.4708 66.8309 25.334 66.6076C25.1328 66.2728 25.2374 65.8424 25.5754 65.6431L48.5031 51.8364C48.8411 51.6291 49.2757 51.7407 49.485 52.0755C49.6861 52.4104 49.5815 52.8408 49.2435 53.0401L26.3158 66.8468C26.2031 66.9185 26.0744 66.9504 25.9456 66.9504Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M47.9237 50.0827H21.286C20.8917 50.0827 20.5778 49.7638 20.5778 49.3812C20.5778 48.9985 20.8997 48.6797 21.286 48.6797H47.9318C48.3261 48.6797 48.6399 48.9985 48.6399 49.3812C48.6399 49.7638 48.318 50.0827 47.9318 50.0827H47.9237Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M51.3842 53.1278C53.4776 53.1278 55.1746 51.4468 55.1746 49.3732C55.1746 47.2996 53.4776 45.6187 51.3842 45.6187C49.2908 45.6187 47.5938 47.2996 47.5938 49.3732C47.5938 51.4468 49.2908 53.1278 51.3842 53.1278Z"
                                    fill="white"
                                />
                                <path
                                    d="M51.3842 53.8373C48.9055 53.8373 46.8856 51.8365 46.8856 49.3813C46.8856 46.926 48.9055 44.9252 51.3842 44.9252C53.8629 44.9252 55.8828 46.926 55.8828 49.3813C55.8828 51.8365 53.8629 53.8373 51.3842 53.8373ZM51.3842 46.3361C49.6861 46.3361 48.31 47.6993 48.31 49.3813C48.31 51.0633 49.6861 52.4264 51.3842 52.4264C53.0822 52.4264 54.4584 51.0553 54.4584 49.3813C54.4584 47.7072 53.0742 46.3361 51.3842 46.3361Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M26.9274 68.7999C26.9274 70.8725 25.2294 72.5545 23.137 72.5545C21.0446 72.5545 19.3465 70.8725 19.3465 68.7999C19.3465 66.7273 21.0446 65.0453 23.137 65.0453C25.2294 65.0453 26.9274 66.7273 26.9274 68.7999Z"
                                    fill="white"
                                />
                                <path
                                    d="M23.137 73.2559C20.6583 73.2559 18.6383 71.2551 18.6383 68.7998C18.6383 66.3446 20.6583 64.3438 23.137 64.3438C25.6156 64.3438 27.6356 66.3446 27.6356 68.7998C27.6356 71.2551 25.6156 73.2559 23.137 73.2559ZM23.137 65.7467C21.4389 65.7467 20.0628 67.1178 20.0628 68.7919C20.0628 70.4659 21.4389 71.837 23.137 71.837C24.835 71.837 26.2112 70.4659 26.2112 68.7919C26.2112 67.1178 24.827 65.7467 23.137 65.7467Z"
                                    fill="#3A2382"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_149_1748">
                                    <rect
                                        width="100"
                                        height="103.191"
                                        fill="white"
                                        transform="translate(0 0.308472)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="heading relative mt-6">
                        <h1 className="header-card dark:text-primSky-950 relative z-10">هـنجهزك</h1>
                        <div className="absolute inset-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="95"
                                height="44"
                                viewBox="0 0 95 44"
                                fill="none"
                            >
                                <ellipse
                                    cx="47.5"
                                    cy="21.8085"
                                    rx="47.5"
                                    ry="21.5"
                                    className="fill-secYellow-800 dark:fill-secYellow-400"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="desc mt-10">
                        <p className="dark:text-primSky-950">
                            {" "}
                            مش محتاج تسأل هذاكر إيه النهاردة.. إحنا هنقول لك
                        </p>
                    </div>
                </div>
            </div>
            <div className="flex justify-center flex-col items-center">
                <div
                    className="min-h-[345px] bg-primSky-800 dark:bg-primSky-300 flex justify-center flex-col items-center px-2 py-5"
                    style={{ borderRadius: "24px" }}
                >
                    <div className="svg">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="100"
                            height="108"
                            viewBox="0 0 100 108"
                            fill="none"
                        >
                            <g clipPath="url(#clip0_149_1772)">
                                <path
                                    d="M31.5423 104.494C5.4464 95.3239 -7.77013 59.4347 4.80133 35.186C17.4388 10.7761 50.7037 12.836 71.5804 28.5081C91.9733 44.3196 105.014 66.2959 97.8449 84.2478C90.9984 102.038 57.8801 113.877 31.5423 104.501V104.494Z"
                                    fill="#CEEBFF"
                                />
                                <path
                                    d="M98.9458 20.817C102.007 15.2697 98.1666 7.2845 90.3685 2.9815C82.5705 -1.3215 73.7674 -0.312821 70.7064 5.23445C67.6454 10.7817 71.4856 18.767 79.2837 23.07C87.0818 27.373 95.8848 26.3643 98.9458 20.817Z"
                                    fill="#CEEBFF"
                                />
                                <path
                                    d="M73.9921 11.5385H25.8173C22.8822 11.5385 20.5029 13.9178 20.5029 16.8529V84.8195C20.5029 87.7546 22.8822 90.134 25.8173 90.134H73.9921C76.9272 90.134 79.3066 87.7546 79.3066 84.8195V16.8529C79.3066 13.9178 76.9272 11.5385 73.9921 11.5385Z"
                                    fill="white"
                                />
                                <path
                                    d="M73.9921 90.7864H25.8247C22.5333 90.7864 19.8578 88.1109 19.8578 84.8195V16.853C19.8578 13.5617 22.5333 10.8861 25.8247 10.8861H73.9921C77.2834 10.8861 79.9589 13.5617 79.9589 16.853V84.8195C79.9589 88.1109 77.2834 90.7864 73.9921 90.7864ZM25.8247 12.1909C23.2517 12.1909 21.1553 14.2874 21.1553 16.8603V84.8269C21.1553 87.3998 23.2517 89.4963 25.8247 89.4963H73.9921C76.565 89.4963 78.6615 87.3998 78.6615 84.8269V16.853C78.6615 14.28 76.565 12.1836 73.9921 12.1836H25.8247V12.1909Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M58.7597 7.60938H41.057C39.3648 7.60938 37.993 8.9812 37.993 10.6734V14.6465C37.993 16.3387 39.3648 17.7105 41.057 17.7105H58.7597C60.4519 17.7105 61.8238 16.3387 61.8238 14.6465V10.6734C61.8238 8.9812 60.4519 7.60938 58.7597 7.60938Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M36.9154 27.4452H29.6291V34.7315H36.9154V27.4452Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M70.1876 28.618H41.7387V29.9155H70.1876V28.618Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M70.1876 32.2612H41.7387V33.5587H70.1876V32.2612Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M36.9154 41.1823H29.6291V48.4686H36.9154V41.1823Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M70.1876 42.3551H41.7387V43.6526H70.1876V42.3551Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M70.1876 45.9983H41.7387V47.2958H70.1876V45.9983Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M36.9154 54.9192H29.6291V62.2055H36.9154V54.9192Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M70.1876 56.092H41.7387V57.3895H70.1876V56.092Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M70.1876 59.7352H41.7387V61.0327H70.1876V59.7352Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M36.9154 68.6562H29.6291V75.9426H36.9154V68.6562Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M70.1876 69.8291H41.7387V71.1266H70.1876V69.8291Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M70.1876 73.4723H41.7387V74.7698H70.1876V73.4723Z"
                                    fill="#3A2382"
                                />
                                <path
                                    d="M53.3939 12.1909H46.4228C45.3233 12.1909 44.429 11.2966 44.429 10.197V4.83124C44.429 3.7317 45.3233 2.8374 46.4228 2.8374H53.3939C54.4935 2.8374 55.3878 3.7317 55.3878 4.83124V10.197C55.3878 11.2966 54.4935 12.1909 53.3939 12.1909ZM46.4228 4.13487C46.0416 4.13487 45.7264 4.45007 45.7264 4.83124V10.197C45.7264 10.5782 46.0416 10.8934 46.4228 10.8934H53.3939C53.7751 10.8934 54.0903 10.5782 54.0903 10.197V4.83124C54.0903 4.44274 53.7751 4.13487 53.3939 4.13487H46.4228Z"
                                    fill="#3A2382"
                                />
                            </g>
                            <defs>
                                <clipPath id="clip0_149_1772">
                                    <rect
                                        width="100"
                                        height="107.484"
                                        fill="white"
                                        transform="translate(0 0.308472)"
                                    />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                    <div className="heading relative mt-6">
                        <h1 className="header-card dark:text-primSky-950 relative z-10">هـتدرب</h1>
                        <div className="absolute inset-0">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="95"
                                height="44"
                                viewBox="0 0 95 44"
                                fill="none"
                            >
                                <ellipse
                                    cx="47.5"
                                    cy="21.8085"
                                    rx="47.5"
                                    ry="21.5"
                                    className="fill-secYellow-800 dark:fill-secYellow-400"
                                />
                            </svg>
                        </div>
                    </div>
                    <div className="desc mt-10">
                        <p className="dark:text-primSky-950">
                            امتحانات إلكترونية تقدر تعيدها لحد ما تتقنها
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Card;
