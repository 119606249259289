import "./teacher.css";
// import Content from "./Content";
import ParallaxTeacher from "./Parallax";
import React, { useEffect, useState } from "react";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
// import LoadingIcon from "../../../components/ui/LoadingIcon";
import { Link } from "react-router-dom";
import { baseURL } from "../../../config";
import LoadingIcon from "../../ui/LoadingIcon";
export default function TeacherLine({ department_id = 0, subjectId = 0, isSubject = false }) {
    const [moveX, setMoveX] = useState(0);
    const [isHovering, setIsHovering] = useState(false);
    const contentWidth = 1000; // Specify the width of the content here

    //   const handleCarouselLeft = () => {
    //     if (moveX === 0) {
    //       setMoveX(200);
    //     } else {
    //       setMoveX((moveX + contentWidth) % (contentWidth * 4)); // Calculate the modulus of moveX to wrap back to 0
    //     }
    //   };

    //   const handleCarouselRight = () => {
    //     setMoveX((moveX - contentWidth + contentWidth * 4) % (contentWidth * 4));
    //   };

    //   console.log(moveX);

    const handleHover = () => {
        setIsHovering(!isHovering);
    };

    const [teachers, setTeachers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const getTeachers = async () => {
        setIsLoading(true);
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);

        let result;
        // console.log(subjectId);
        if (isSubject) {
            result = await http.get(`api/subjects/${subjectId}/teachers`, config);
            // console.log(subjectId);
        } else {
            result = await http.get(`api/departments/${department_id}/teachers`, config);
        }

        const { data } = result;

        setTeachers([]);
        setTeachers(data);
        setIsLoading(false);
    };

    useEffect(() => {
        if (department_id > 0 || subjectId > 0) {
            getTeachers();
        }
    }, [department_id, subjectId]);

    return (
        <section className="ScrollLine">
            {isLoading ? (
                <div className="flex-center-both">
                    <div className="border-2 rounded-md border-blue-300 dark:border-blue-500 p-10 bg-blue-100 dark:bg-opacity-5 smooth clr-text-primary flex space-x-2 space-x-reverse">
                        <LoadingIcon className="font-h1 text-blue-500" />
                        <span>يتم الآن تحميل المدرسين ...</span>
                    </div>
                </div>
            ) : teachers.length < 1 ? (
                <div className="flex-center-both">
                    <div className="border-2 rounded-md border-rose-300 dark:border-rose-500 p-10 bg-rose-100 dark:bg-opacity-5 smooth clr-text-primary">
                        سيتم اضافة مدرسين داخل هذه الشبعة قريبًا
                    </div>
                </div>
            ) : (
                <>
                    {/* <div className="carousel-arrows">
        <button onClick={handleCarouselLeft}>{"<"}</button>
        <button onClick={handleCarouselRight}>{">"}</button>
      </div> */}
                    <div
                        className="firstLineTeacher bg-primSky-800 dark:bg-primSky-400"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                    >
                        <ParallaxTeacher baseVelocity={isHovering ? 0 : -5} moveX={moveX}>
                            <div className="lineTeacher">
                                {teachers.map((teacher, index) => (
                                    <Link to={`/teacher/${teacher.id}`} key={index}>
                                        <div className="img-container" key={index}>
                                            <img
                                                className="image"
                                                src={`${baseURL}/${teacher.picture}`}
                                                alt="test"
                                            />
                                        </div>
                                        <div className="flex flex-col space-y-2">
                                            {/* <div className="py-2">
                                                <div className="bg-yellow-500 rounded-md px-2 pb-4">
                                                </div>
                                            </div> */}
                                            <span className="text-center pt-4 text-xl vipFont font-w-bold ">
                                                {teacher.name}
                                            </span>
                                            <span className="text-center pb-7 text-xl ">
                                                {teacher.description}
                                            </span>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </ParallaxTeacher>
                    </div>
                </>
            )}
        </section>
    );
}
