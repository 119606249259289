import React, { useContext, useEffect, useState } from "react";
import InputField from "../../../components/form/elements/InputField";
import { handleInputChange } from "../../../services/formServices";
import { isMultiYear } from "../../../services/defaultSettings";
import { years } from "../../../services/yearSevices";
import Container from "../../../components/ui/Container";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import AuthContext from "../../../context/AuthContext";
import { isEmptyObject } from "jquery";
import SubjectCard from "../../../components/NewUi/subjectCard/SubjectCard";
import Heading from "../../../components/NewUi/heading/Heading";
import TeacherLine from "../../../components/NewUi/teacherHorizontal/TeacherLine";
// import { ScrollingProvider } from "../../../context/ScrollingContext";
// import SectionHead from "../../../components/ui/SectionHead";
// import SubjectsGrid from "./SubjectsGrid";
// import TeachersGrid from "./TeachersGrid";
// import CoursesGrid from "./CoursesGrid";
// import SideTitle from "../../../components/ui/SideTitle";
// import Categorie from "./Categorie";
// import { values } from "lodash";

const SubjectsSection = () => {
    const [filterData, setFilterData] = useState({
        year: 3,
        department_id: 0,
    });
    const [departments, setDepartments] = useState([]);
    const [categorieApi, setCategorieApi] = useState("");

    const { user } = useContext(AuthContext);
    const [first, setFirst] = useState([]);
    const [second, setSecond] = useState([]);
    const [third, setThird] = useState([]);
    const toUseYears = years.filter((value, index) => index < 3);

    const getSubjects = async () => {
        const { data } = await http.get(`api/with_year_id/subjects/options`);
        // console.log(data);
        const FilterThird = data.filter((subject) => subject.year === "3");
        const FilterTwo = data.filter((subject) => subject.year === "2");
        const FilterOne = data.filter((subject) => subject.year === "1");

        setFirst(FilterOne.map((item) => [item.text, item.value]));
        setSecond(FilterTwo.map((item) => [item.text, item.value]));
        setThird(FilterThird.map((item) => [item.text, item.value]));

        //    console.log(data.filter((subject)=>subject.year === '3'))
        //    console.log(FilterThird.map((item) => [item.text, item.value]))
    };
    const getDepartments = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data } = await http.get(`api/years/${filterData.year}/departments/options`, config);
        // console.log(data);
        // console.log(filterData)
        setDepartments([]);
        if (data.length < 1) {
            setFilterData({ ...filterData, department_id: 0 });
        } else {
            setFilterData({ ...filterData, department_id: data[0]["value"] });
        }
        setDepartments(data);
        console.log(filterData.department_id);
    };
    // console.log(departments)
    useEffect(() => {
        getSubjects();
        getDepartments();
    }, [filterData.year]);

    useEffect(() => {
        if (!isEmptyObject(user) && user.year && user.year != filterData.year) {
            setFilterData({
                ...filterData,
                year: user.year,
            });
        }
    }, [user]);

    useEffect(() => {
        if (filterData.department_id > 0) {
            setCategorieApi(`/api/departments/${filterData.department_id}/courses`);
        }
    }, [filterData.department_id]);

    return (
        <div className="relative mb-20 mt-10 overflow-hidden" id="courses">
            {/* <ScrollingProvider>
                <SectionHead title={"محتوى المنصة"} /> 
            </ScrollingProvider> */}
            <div className="">
                <Container>
                    <Heading title="مواد" subtitle="بسطتهالك" />
                    <SubjectCard first={first} sec={second} third={third} />
                </Container>

                <Container className="my-[10rem]">
                    <div>
                        <Heading title="أخــتار" subtitle="مُـدرســك" />
                    </div>

                    <div
                        className={
                            isEmptyObject(user) || !user.year
                                ? "flex-center-both"
                                : "flex items-center justify-start"
                        }
                    >
                        <div className="flex-center-both md:flex-row flex-col px-10 py-3 rounded-md md:space-x-4 md:space-x-reverse space-y-4 md:space-y-0 bg-yellow-300 dark:bg-yellow-500 smooth clr-text-primary">
                            {isMultiYear && (isEmptyObject(user) || !user.year) ? (
                                <InputField
                                    onChange={handleInputChange}
                                    data={filterData}
                                    setData={setFilterData}
                                    type="select"
                                    options={toUseYears}
                                    id="year"
                                    placeholder="اختر الصف الدراسي"
                                    // errors={errors}
                                />
                            ) : (
                                ""
                            )}
                            <InputField
                                onChange={handleInputChange}
                                data={filterData}
                                setData={setFilterData}
                                type="select"
                                options={departments}
                                id="department_id"
                                placeholder="اختر الشعبة"
                            />
                        </div>
                    </div>
                </Container>
                <div>
                    {filterData.department_id === 0 ? (
                        <Container>
                            <div className="flex-center-both">
                                <div className="border-2 rounded-md border-yellow-300 dark:border-yellow-500 p-10 bg-yellow-100 dark:opacity-5 smooth clr-text-primary">
                                    اختر الشعبة الدراسية اولًا لإختيار المادة !
                                </div>
                            </div>
                        </Container>
                    ) : (
                        <>
                            <div className="w-screen">
                                {" "}
                                <TeacherLine department_id={filterData.department_id} />{" "}
                            </div>
                            {/* <SubjectsGrid department_id={filterData.department_id} /> */}
                            {/* <TeachersGrid department_id={filterData.department_id} /> */}
                            {/* <Categorie
                                titleFirst="كورسات"
                                titleLast="الشعبة"
                                noCoursesPlaceholder={"سيتم اضافة الكورسات داخل هذه الشبعة قريبًا"}
                                api={categorieApi}
                            /> */}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SubjectsSection;
