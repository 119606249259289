import React, { useContext, useState } from "react";
import {
    English,
    Arabic,
    France,
    italy,
    germany,
    Math,
    Geo,
    Bio,
    chemistry,
    Brain,
    book,
} from "./Svgs";
import { Link } from "react-router-dom";
import Button from "../../ui/Button";
import AuthContext from "../../../context/AuthContext";

function SubjectCard({ first, sec, third }) {
    // console.log(first);
    const [isSeeMore1, setIsSeeMore1] = useState(false);
    const [isSeeMore2, setIsSeeMore2] = useState(false);
    const [isSeeMore3, setIsSeeMore3] = useState(false);

    const { token, user } = useContext(AuthContext);
    return (
        <div className="w-full h-full flex items-center justify-center">
            <div className="flex items-start justify-center flex-wrap h-full w-full gap-5 md:gap-20 lg:gap-10 xl:gap-5">
                {!token || user.year === 1 ? (
                    <div className="flex flex-col gap-5">
                        <div className="relative flex h-fit width items-center justify-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="231"
                                height="101"
                                viewBox="0 0 231 101"
                                fill="none"
                            >
                                <ellipse
                                    cx="115.5"
                                    cy="50.5"
                                    rx="115.5"
                                    ry="50.5"
                                    className="fill-secYellow-600 dark:fill-secYellow-400"
                                />
                            </svg>
                            <h1 className="absolute right-[20%] md:font-w-bold text-3xl md:text-4xl  ">
                                الصف الأول الثانوي
                            </h1>
                        </div>

                        <div className=" w-full m-auto sm:w-[400px] h-full px-3 py-6 rounded-3xl bg-primSky-600 dark:bg-primSky-400 p-3 flex gap-2 justify-evenly items-center flex-col">
                            {first.map((item, index) => {
                                if (index < 3 || isSeeMore1) {
                                    return (
                                        <Link to={`/subject/${item[1]}`} key={index}>
                                            <p
                                                className="text-4xl smooth hover:bg-[#102542] rounded-2xl px-5 py-2 font-h3 font-bold text-center text-primSky-950"
                                                key={index}
                                            >
                                                {item[0]}
                                            </p>
                                        </Link>
                                    );
                                }
                            })}
                            <Button color="yellow" onClick={() => setIsSeeMore1(!isSeeMore1)}>
                                {isSeeMore1 ? "اخفاء" : "عرض المزيد"}
                            </Button>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                {!token || user.year === 2 ? (
                    <div className="flex flex-col gap-5 mt-0 lg:mt-14">
                        <div className="relative flex h-fit width items-center justify-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="231"
                                height="101"
                                viewBox="0 0 231 101"
                                fill="none"
                            >
                                <ellipse
                                    cx="115.5"
                                    cy="50.5"
                                    rx="115.5"
                                    ry="50.5"
                                    className="fill-secYellow-600 dark:fill-secYellow-400"
                                />
                            </svg>
                            <h1 className="absolute right-[20%] md:font-w-bold text-3xl md:text-4xl ">
                                الصف الثاني الثانوي
                            </h1>
                        </div>
                        <div className=" w-full m-auto sm:w-[400px] h-full px-3 py-6 rounded-3xl bg-primSky-600 dark:bg-primSky-400 p-3 flex gap-2 flex-col items-center justify-evenly">
                            {sec.map((item, index) => {
                                if (index < 3 || isSeeMore2) {
                                    return (
                                        <Link to={`/subject/${item[1]}`} key={index}>
                                            <p
                                                className="text-4xl smooth hover:bg-[#102542] rounded-2xl px-5 py-2 font-h3 font-bold text-center text-primSky-950"
                                                key={index}
                                            >
                                                {item[0]}
                                            </p>
                                        </Link>
                                    );
                                }
                            })}
                            <Button color="yellow" onClick={() => setIsSeeMore2(!isSeeMore2)}>
                                {isSeeMore2 ? "اخفاء" : "عرض المزيد"}
                            </Button>
                        </div>
                    </div>
                ) : (
                    ""
                )}

                {!token || user.year == 3 ? (
                    <div className="flex flex-col gap-5 mt-0 lg:mt-24">
                        <div className="relative flex h-fit width items-center justify-center">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="231"
                                height="101"
                                viewBox="0 0 231 101"
                                fill="none"
                            >
                                <ellipse
                                    cx="115.5"
                                    cy="50.5"
                                    rx="115.5"
                                    ry="50.5"
                                    className="fill-secYellow-600 dark:fill-secYellow-400"
                                />
                            </svg>
                            <h1 className="absolute right-[20%] md:font-w-bold text-3xl md:text-4xl ">
                                الصف الثالث الثانوي
                            </h1>
                        </div>
                        <div className="w-full m-auto sm:min-w-[400px] h-full px-3 py-6 rounded-3xl bg-primSky-600 dark:bg-primSky-400 p-3 flex gap-2 flex-col items-center justify-evenly">
                            {third.map((item, index) => {
                                if (index < 3 || isSeeMore3) {
                                    return (
                                        <Link to={`/subject/${item[1]}`} key={index}>
                                            <p
                                                className="text-4xl smooth hover:bg-[#102542] rounded-2xl px-5 py-2 font-h3 font-bold text-center text-primSky-950"
                                                key={index}
                                            >
                                                {item[0]}
                                            </p>
                                        </Link>
                                    );
                                }
                            })}
                            <Button color="yellow" onClick={() => setIsSeeMore3(!isSeeMore3)}>
                                {isSeeMore3 ? "اخفاء" : "عرض المزيد"}
                            </Button>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
}

export default SubjectCard;
