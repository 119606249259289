import React from "react";
import HoursMiniCards from "../../components/ui/HoursMiniCards";
import Container from "../../components/ui/Container";
import PlannerImg from "../../assets/Updated-imagery/plannerSvg.svg";

const StudyPlanMainSection = () => {
    return (
        <section className="lg:h-screen min-h-screen relative overflow-hidden flex lg:items-center lg:justify-center lg:mb-[10rem] home-main_section">
            <Container className="h-auto relative z-10">
                <div className="w-full flex lg:flex-row-reverse flex-col-reverse h-full justify-evenly lg:h-screen space-y-20 space-y-reverse lg:space-y-0 relative z-10 items-center">
                    <div className="lg:basis-1/2 basis-full h-full flex-center-both">
                        <div className="max-w-3xl h-full flex-center-both">
                            <img
                                src={PlannerImg}
                                alt={"planner-svg"}
                                className="dark:opacity-90 smooth w-full"
                            />
                        </div>
                    </div>
                    <div className=" lg:basis-1/2 basis-full h-full flex-center-both pt-20 lg:pt-0">
                        <div className="px-10 flex-col space-y-6 lg:space-y-5">
                            <div className="flex justify-center lg:justify-start">
                                <h1 className="font-w-bold text-center md:text-right smooth relative text-4xl lg:text-5xl xl:text-6xl">
                                    <div className="relative z-10 clr-text-primary smooth ">
                                        تفتكر
                                        {"   "}
                                        <span className="text-primSky-500 vipFont ">
                                            بسطتنهالك
                                        </span>{" "}
                                        إزاي؟
                                    </div>
                                </h1>
                            </div>
                            <div className="flex flex-col space-y-10 ">
                                <p className="text-lg text-center md:text-right  lg:text-xl w-full xl:w-3/4">
                                    عشان نضمن انك تخلص كل الي متراكم عليك قررنا نوفر عليك وقت ومجهود
                                    ونطمنك بجدول
                                    <span className="text-primSky-300 font-w-bold">
                                        {" "}
                                        بسطتهالك
                                    </span>{" "}
                                    لكل المواد .. مش محتاج منك غير{" "}
                                    <span className="text-secYellow-300 font-w-bold">
                                        ست ساعات
                                    </span>{" "}
                                    بس في اليوم !{" "}
                                </p>
                                <div className="flex flex-col space-y-3">
                                    <p className="text-lg text-center md:text-right lg:text-xl w-full xl:w-3/4">
                                        لأ بص هتقولي يومي مليان وحجج واعذار متخافش قايسين يومك
                                        بالثانيه احنا مقدرين قيمة وقتك ومزودينلك عليهم ساعة مرقعة
                                    </p>
                                    <div className=" w-full 2xl:w-3/4">
                                        <HoursMiniCards />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default StudyPlanMainSection;
