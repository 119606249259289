import React from "react";
import Container from "../../components/ui/Container";
import { CtaImagery, CtaTime, CtaWorld } from "./SvgCta";
import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
import { isStoreLocatorAvailable } from "../../services/defaultSettings";

const CtaStudent = () => {
    const token = auth.getToken();
    return (
        <section className="h-full rounded-tl-[40px] md:rounded-tl-[128px] py-16  rounded-tr-none rounded-br-none rounded-bl-[40px] md:rounded-bl-[128px] bg-primSky-500 dark:bg-primSky-400 px-4 md:px-12">
            <Container className="h-full relative z-10  pt-16 md:pt-0 pb-20 md:pb-0 space-y-4">
                <div className="w-full h-full flex flex-col lg:flex-row-reverse justify-evenly lg:justify-between items-center gap-20 lg:gap-0">
                    <div className="flex justify-center lg:justify-end items-center lg:basis-1/2">
                        <img
                            className="w-full sm:w-[400px] h-full "
                            src={CtaImagery}
                            alt="CtaStudentAnywhere"
                        />
                    </div>
                    <div className="flex flex-col basis-1/2 gap-8 lg:gap-12">
                        <div className=" flex items-center gap-8 lg:gap-20 lg:basis-4/6">
                            <div className="w-[80px] lg:w-[120px]  h-[85px] lg:h-[129px] ">
                                {CtaWorld}
                            </div>
                            <div className="w-[80px] lg:w-[120px]  h-[85px] lg:h-[129px] ">
                                {CtaTime}
                            </div>
                        </div>
                        <div className="flex flex-col w-full">
                            <h1 className="vipFont text-[38px] md:text-[64px] text-white text-right">
                                ذاكر في اي وقت{" "}
                            </h1>
                            <div className="flex items-center gap-4">
                                <span>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="135"
                                        height="30"
                                        viewBox="0 0 135 30"
                                        fill="none"
                                    >
                                        <path
                                            d="M0.585786 13.3944C-0.195262 14.1754 -0.195262 15.4418 0.585786 16.2228L13.3137 28.9507C14.0948 29.7318 15.3611 29.7318 16.1421 28.9507C16.9232 28.1697 16.9232 26.9034 16.1421 26.1223L4.82843 14.8086L16.1421 3.49489C16.9232 2.71384 16.9232 1.44751 16.1421 0.666458C15.3611 -0.114591 14.0948 -0.114591 13.3137 0.666458L0.585786 13.3944ZM135 12.8086H2V16.8086H135V12.8086Z"
                                            fill="#E7F8FD"
                                        />
                                    </svg>
                                </span>
                                <p className="vipFont text-[28px] md:text-[36px]">
                                    في أي <span className="text-secYellow-500">مكان</span>
                                </p>
                            </div>
                            {isStoreLocatorAvailable && (
                                <p className="mt-4 w-2/3 text-[24px]">
                                    يعني دلوقتي وفرنالك منافذ تقدر تشتري من خلالها الأكواد والكتب في
                                    جميع المحافظات خش شوف دلوقتي منافذ البيع القريبه ليك وابسط يعم
                                </p>
                            )}
                        </div>
                        <div className="mt-8 self-center lg:self-start ">
                            {isStoreLocatorAvailable ? (
                                <Button
                                    color="MajorelleBlue"
                                    className="text-xl font-bold md:text-3xl px-[25px] md:px-[50px] py-[15px] md:py-[25px] rounded-xl sm:rounded-3xl inline-block scale smooth shadow-md hover:shadow-lg  border-none  "
                                    element="Link"
                                    to={`/store_locator`}
                                >
                                    <span className="text-[#ECE8FD]">هـبسطهالك</span>
                                </Button>
                            ) : (
                                <Button
                                    color="MajorelleBlue"
                                    className="text-xl font-bold md:text-3xl px-[25px] md:px-[50px] py-[15px] md:py-[25px] rounded-xl sm:rounded-3xl inline-block scale smooth shadow-md hover:shadow-lg  border-none  "
                                    element="Link"
                                    to={`${token ? "/me/user/courses" : "/register"}`}
                                >
                                    {token ? (
                                        <span>كورساتك </span>
                                    ) : (
                                        <span className="text-[#ECE8FD]">هـبسطهالك</span>
                                    )}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default CtaStudent;
