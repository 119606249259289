import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import AuthContext from "../context/AuthContext";

import registerPicture from "../assets/register.jpeg";
import HalfPage from "../components/ui/HalfPage";
import Button from "../components/ui/Button";

const AccountCreationStatus = () => {
    const [user, setUser] = useState({
        first_name: "",
        last_name: "",
        phone: "",
        father_phone: "",
        government_id: "",
        year: 3,
        email: "",
        password: "",
        password_confirmation: "",
    });

    const { accountRequest } = useContext(AuthContext);

    // useEffect({});

    const settings = {
        pending: {
            containerClassName: "border-yellow-500 bg-yellow-500 bg-opacity-10",
            className: "bg-yellow-500",
            text: "قيد المراجعة",
        },
        declined: {
            containerClassName: "border-rose-500",
            className: "bg-rose-500",
            text: "مرفوض",
        },
    };

    return (
        <HalfPage picture={registerPicture}>
            <div className={`w-4/5 h-4/5 p-10 lg:px-10 flex-col`}>
                {accountRequest && (
                    <div
                        className={`flex-center-both h-full border-2 rounded-md p-2 ${
                            settings[accountRequest.request_status]["containerClassName"]
                        }`}
                    >
                        <div className="pb-10 space-y-10 flex-center-both flex-col">
                            <div className="font-big font-w-bold space-x-2 space-x-reverse">
                                <span className="font-h3">أهلًا </span>
                                <span className="underline">{accountRequest.first_name}</span>
                            </div>
                            <div className=" space-y-4">
                                <div className="">
                                    تم إنشاء حسابك و سيتم مراجعته من خلال فريق الدعم تقدر تسجل دخول
                                    خلال <span className="font-w-bold">٤٨ ساعة</span>
                                </div>
                                <div className="flex-center-both space-x-2 space-x-reverse">
                                    <span className="font-w-bold">حالة حسابك : </span>
                                    <div
                                        className={`px-3 pb-0.5 text-slate-50 rounded-md font-w-bold ${
                                            settings[accountRequest.request_status]["className"]
                                        }`}
                                    >
                                        {settings[accountRequest.request_status]["text"]}
                                    </div>
                                </div>
                            </div>
                            {accountRequest.request_status === "declined" ? (
                                <div className="space-y-2 flex-center-both flex-col">
                                    <div>سبب الرفض :</div>
                                    <div className="bg-rose-500 p-5 rounded-md shadow-md">
                                        {accountRequest.message}
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                )}
                <div className="w-full flex-center-both pt-5 px-5">
                    <Button element="Link" to="/">
                        الرجوع
                    </Button>
                </div>
            </div>
        </HalfPage>
    );
};

export default AccountCreationStatus;
