import React from "react";
import Teachers from "../../components/NewUi/horizontalAnimation/Teachers";
import Heading from "../../components/NewUi/heading/Heading";
import Container from "../../components/ui/Container";
import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
const TeacherSection = () => {
    const token = auth.getToken();

    return (
        <section className="min-h-[80vh] relative overflow-hidden my-10">
            <Container className="h-full relative z-10 font-the-sans pt-32 md:pt-0 pb-20 md:pb-0 space-y-4 flex flex-col items-center justify-center g-[104px] lg:g-[128px]">
                <div className="flex flex-col items-center justify-center gap-[104px] lg:gap-30">
                    <div className="flex flex-col items-center justify-center h-fit gap-10">
                        <Heading title="مُدرسين" subtitle="بسطتهالك" />

                        <p className="text-center w-full text-[24px] lg:max-w-[800px]">
                            بخطوات بسيطة بإمكانك الوصول الى أكفأ المدرسين اونلاين ليقوموا بمساعدتك
                            والاجابة على اسئلتك الخاصة بالمنهج الدراسي.
                        </p>
                    </div>
                    <Button
                        color="NewYello"
                        className="text-xl font-bold md:text-3xl px-[25px] md:px-[50px] py-[15px] md:py-[25px] rounded-xl sm:rounded-3xl inline-block scale smooth shadow-md hover:shadow-lg  border-none  "
                        element="Link"
                        to={`${token ? "/me/user/courses" : "/register"}`}
                    >
                        {token ? (
                            <span>استمتع بالشرح </span>
                        ) : (
                            <span className="text-primSky-100">اشترك الأن</span>
                        )}
                    </Button>
                </div>
                <Teachers />
            </Container>
        </section>
    );
};

export default TeacherSection;
